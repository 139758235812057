// Angular Modules
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';

// External Modules
import { forkJoin, map, Observable } from 'rxjs';

// Models
import { QueryParameters, QueryResults } from '@models/index';
import { Status } from '@configuration/status/models/index';

// Services
import { HttpQueryUtils } from '@services/http-query-utils.service';

const BACKEND_API = `${environment.apiBaseUrl}`;
const ENTITY = 'status'


@Injectable({
  providedIn: 'root'
})
export class StatusService {

  constructor(
    private http: HttpClient,
    public httpQueryUtils: HttpQueryUtils) { }

  // get httpParams() {
  //   return new HttpParams()//.set('fields', 'name,capital,alpha2Code,flags.png,population');
  // }


  find(queryParameters: QueryParameters): Observable<QueryResults<Status>> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    const queryResults$ =  this.httpQueryUtils.executeQuery<Status>( url, queryParameters);

    return  queryResults$;
  }


  delete(id: number): Observable<any> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;

    return this.http.delete(url);
  }


  deleteBulk(ids: Array<number>): Observable<any> {

    const colObservablesToDelete= [];

    for (const id of ids) {
      colObservablesToDelete.push(this.delete(id));
    }

    return forkJoin( colObservablesToDelete );
  }


  add(entity: Status): Observable<Status> {

    const url = `${BACKEND_API}/${ENTITY}/`;

    return this.http.post<Status>(url, entity);
  }


  update(entity: Status): Observable<Status> {

    const url = `${BACKEND_API}/${ENTITY}/${entity.id}/`;

    return this.http.put<Status>(url, entity);
  }


  findById(id: number): Observable<Status> {

    const url = `${BACKEND_API}/${ENTITY}/${id}/`;
    return this.httpQueryUtils.executeQuery<Status>(url, {})
            .pipe(
                map(result => result.items[0])
              )
  }


  getStatusColor(entity: Status): string {

    let colorHex = '';

    if (entity.color) {
      colorHex = entity.color;
    }

    switch (entity.code) {
      case '30':
        colorHex = '#e400ea';
        break;
      case '40':
        colorHex = '#07226d';
        break;
      case '50':
        colorHex = '#1b6d00';
        break;
      case '60':
        colorHex = '#d61616';
        break;
    }

    return colorHex;
  }

}
